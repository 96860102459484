import React, {useEffect, useState} from "react";
import {FieldProps, useModeController} from "@firecms/core";
import Handlerbars from "handlebars";
import {TextField, Label, Paper, Tooltip, IconButton, InfoIcon, Typography} from "@firecms/ui";
import CodeMirror from "@uiw/react-codemirror";
import {json} from "@codemirror/lang-json";


function filterObjectProperties(
  properties: object,
  allowedProperties: string[]
): object {
  const filtered = allowedProperties.reduce((filtered, prop) => {
    if (prop in properties) {
      filtered[prop] = properties[prop as keyof typeof properties];
    }
    return filtered;
  }, {} as Record<string, unknown>);

  return JSON.parse(JSON.stringify(filtered));
}

const isHandlerbarTemplate = (str: string) => {
  return str.includes("{{") || str.includes("}}");
}

const getUnclosedDoubleBrackets = (str: string) => {
  const match = str.match(/{{\s*([^}]+?)(?:\s|$)/);
  return match ? match[1].trim() : null;
}

interface TemplatedTextFieldProps {
  properties: object
  allowedProperties: string[]
  multiLine: boolean
}

export default function TemplatedTextField({
                                             property,
                                             value,
                                             setValue,
                                             setFieldValue,
                                             customProps,
                                             touched,
                                             includeDescription,
                                             showError,
                                             error,
                                             isSubmitting,
                                             context, // the rest of the entity values here
                                             ...props
                                           }: FieldProps<string, TemplatedTextFieldProps>) {
  const [previewTemplate, setPreviewTemplate] = useState<string | null>(null);
  const [internalError, setInternalError] = useState<string | null>(null);
  const [showContextData, setShowContextData] = useState<boolean>(false);

  const { mode } = useModeController();

  const contextData = filterObjectProperties(customProps.properties, customProps.allowedProperties);

  const handleInputChange = (newInputValue: string) => {
    try {
      setShowContextData(!!getUnclosedDoubleBrackets(newInputValue));
      if (isHandlerbarTemplate(newInputValue)) {
        const content = Handlerbars.compile(newInputValue)
        setPreviewTemplate(content(customProps.properties));
      } else {
        setPreviewTemplate(null);
      }
      setInternalError(null);
    } catch (e) {
      if (e && typeof e.toString === 'function') {
        const rawError = e.toString();
        console.log(Object.keys(e));
        setInternalError(`Error in template: ${rawError}`);
      }
      setPreviewTemplate(newInputValue)
    }
  }

  useEffect(() => {
    if(isHandlerbarTemplate(value ?? "")) handleInputChange(value ?? "");
  }, []);


  const onTextChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(event.target.value);
    handleInputChange(event.target.value);
  }

  return (
    <>
      <TextField
        error={!!error}
        disabled={isSubmitting}
        label={error ?? property.name}
        multiline={customProps.multiLine ?? false}
        value={value ?? ""}
        onChange={onTextChange}/>


      <Paper className={`p-4 mt-2 ${!previewTemplate ? "hidden" : ""}`}>
        <>
          <Typography variant={"caption"}
                      color={"secondary"}
                      className={"flex-grow"}>
            {"Preview of the rendered template"}
          </Typography>
          <Label>{previewTemplate ?? ""}</Label>

          {internalError &&
            <>
              <p>
              <Typography variant={"label"}
                          color={"error"}
                          className={"flex-grow"}>
                {"Error rendering template"}
              </Typography>
              </p>
            </>
          }
        </>
      </Paper>

      <div className={"flex ml-3.5 mt-1"}>
        <Typography variant={"caption"}
                    color={"secondary"}
                    className={"flex-grow"}>
          {property.description}
        </Typography>

        {property.longDescription &&
          <Tooltip title={"Dynamic field, it can be templated with Handlebars, example:\nHello, my name is {{name}}"}
                   side="bottom"
                   asChild={true}>
            <IconButton
              size={"small"}
              className="self-start">

              <InfoIcon color={"disabled"}
                        size={"small"}/>
            </IconButton>
          </Tooltip>}

      </div>

      <Paper className={`mt-2 ${!showContextData ? "hidden" : ""}`}>
        <CodeMirror value={JSON.stringify(contextData, null, "\t")} height="240px" extensions={[json()]} theme={mode} />
      </Paper>


    </>

  );

}
