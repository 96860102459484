import React from "react";
import { EntityCustomView, PropertyFieldBinding, useAuthController } from "@firecms/core";
import { Container } from "@firecms/ui";
import { MemberRole } from "../collections/team";

export const chatbotPromptView: EntityCustomView = {
  key: "prompt_editor",
  name: "Prompt Editor",
  includeActions: true,
  Builder: ({
              collection,
              entity,
              modifiedValues,
              formContext
            }) => {
    const authController = useAuthController();
    const hasPermission = [MemberRole.admin, MemberRole.editor].includes(authController.extra?.role);
    return (
      <Container className={"my-16 flex flex-col gap-4  w-full"}>
        <PropertyFieldBinding context={formContext}
                              property={{
                                dataType: "string",
                                name: "Prompt",
                                readOnly: !hasPermission,
                                multiline: true
                              }}
                              propertyKey={"prompt"}/>
        <PropertyFieldBinding context={formContext}
                              property={{
                                dataType: "string",
                                name: "Restrictions",
                                readOnly: !hasPermission,
                                multiline: true
                              }}
                              propertyKey={"restrictions"}/>

      </Container>
    )
  }
};
