import React from "react";
import { EntityCustomView, PropertyFieldBinding } from "@firecms/core";
import { Container } from "@firecms/ui";
import { chatbotAssistantProperty } from "../collections/chatbot/chatbot_assistant_properties";
import { chatbotCustomizationProperty } from "../collections/chatbot/chatbot_customization_properties";

export const chatbotCustomizationView: EntityCustomView = {
  key: "customization_editor",
  name: "Customization",
  includeActions: true,
  Builder: ({
              collection,
              entity,
              modifiedValues,
              formContext
            }) => (
    <Container className={"my-16 flex flex-col gap-4 w-full"} >
      <PropertyFieldBinding context={formContext}
                            property={chatbotAssistantProperty}
                            propertyKey={"assistant"}/>
      <PropertyFieldBinding context={formContext}
                            property={chatbotCustomizationProperty}
                            propertyKey={"customization"}/>

    </Container>
  )
};
