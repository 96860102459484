import React, { useCallback } from "react";
import "@fontsource/montserrat";

import {
    AppBar,
    CircularProgressCenter, Drawer,
    FireCMS,
    ModeControllerProvider,
    NavigationRoutes,
    Scaffold,
    SideDialogs,
    SnackbarProvider, useBuildLocalConfigurationPersistence,
    useBuildModeController,
    useBuildNavigationController,
    useValidateAuthenticator
} from "@firecms/core";
import {
    FirebaseAuthController,
    FirebaseLoginView,
    FirebaseSignInProvider,
    useFirebaseAuthController,
    useFirestoreDelegate,
    useInitialiseFirebase
} from "@firecms/firebase";
import { CenteredView } from "@firecms/ui";
import { chatbotsCollection } from "./collections/chatbot/chatbots";
import { teamMembersCollection } from "./collections/team";
import { websitesCollection } from "./collections/websites";
import { firebaseProductionConfig, firebaseStagingConfig } from "./firebase-config";
import { authenticator } from "./auth";
import darkLogo from "./assets/deardoc.png";

import logo from "./assets/logow.png";
import { useFirebaseStorageSource } from "./useFirebaseStorageSource";
import { algoliaSearchControllerBuilder } from "./text_search";
import config from "./config";
import { domainBasedCustomizationCollection } from "./collections/domain_based_customization";
import { abTestingCollection } from "./collections/ab_testing";

function AIChatAdminApp() {
    const collections = [teamMembersCollection, chatbotsCollection, websitesCollection, domainBasedCustomizationCollection, abTestingCollection];

    const firebaseConfig = config.APP_ENV !== "production" ? firebaseStagingConfig : firebaseProductionConfig;
    const {
        firebaseApp,
        firebaseConfigLoading,
        configError
    } = useInitialiseFirebase({
        firebaseConfig
    });

    // Controller used to manage the dark or light color mode
    const modeController = useBuildModeController();

    const signInOptions: FirebaseSignInProvider[] = ["google.com"];

    // Controller for managing authentication
    const authController: FirebaseAuthController = useFirebaseAuthController({
        firebaseApp,
        signInOptions
    });

    // Controller for saving some user preferences locally.
    const userConfigPersistence = useBuildLocalConfigurationPersistence();


    const firestoreDelegate = useFirestoreDelegate({
        firebaseApp,
        textSearchControllerBuilder: algoliaSearchControllerBuilder
    });

    // Controller used for saving and fetching files in storage
    const storageSource = useFirebaseStorageSource({
        firebaseApp
    });

    const authenticatorCallback = useCallback(authenticator, []);

    const {
        authLoading,
        canAccessMainView,
        notAllowedError
    } = useValidateAuthenticator({
        authController,
        authenticator: authenticatorCallback,
        dataSourceDelegate: firestoreDelegate,
        storageSource
    });

    const navigationController = useBuildNavigationController({
        collections,
        authController,
        dataSourceDelegate: firestoreDelegate
    });

    if (firebaseConfigLoading || !firebaseApp) {
        return <>
            <CircularProgressCenter/>
        </>;
    }

    if (configError) {
        return <CenteredView>{configError}</CenteredView>;
    }
    return (
        <SnackbarProvider>
            <ModeControllerProvider value={modeController}>
                <FireCMS
                    navigationController={navigationController}
                    authController={authController}
                    userConfigPersistence={userConfigPersistence}
                    dataSourceDelegate={firestoreDelegate}
                    storageSource={storageSource}
                    plugins={[]}
                >
                    {({
                          context,
                          loading
                      }) => {

                        if (loading || authLoading) {
                            return <CircularProgressCenter size={"large"}/>;
                        }
                        if (!canAccessMainView) {
                            return <FirebaseLoginView authController={authController}
                                                      firebaseApp={firebaseApp}
                                                      signInOptions={signInOptions}
                                                      logo={modeController.mode === "light" ? darkLogo : logo}
                                                      notAllowedError={notAllowedError}/>;
                        }

                        return <Scaffold
                          autoOpenDrawer={false}
                          logo={modeController.mode === "light" ? darkLogo : logo}
                        >
                            <AppBar title={"AI Chat Admin"}/>
                            <Drawer/>
                            <NavigationRoutes/>
                            <SideDialogs/>
                        </Scaffold>;
                    }}
                </FireCMS>
            </ModeControllerProvider>
        </SnackbarProvider>
    );
}

export default AIChatAdminApp;
