import { SearchClient, algoliasearch } from "algoliasearch";
import { performAlgoliaTextSearch, buildExternalSearchController } from "@firecms/firebase";
import config from "./config";

const client: SearchClient | undefined = algoliasearch(config.ALGOLIA_APP_ID, config.ALGOLIA_API_KEY);

export const algoliaSearchControllerBuilder = buildExternalSearchController({
  isPathSupported: (path) => {
    return ["chatbots", "websites"].includes(path);
  },
  search: async ({
                   path,
                   searchString
                 }) => {
    if (path === "chatbots")
      return client && performAlgoliaTextSearch(client, "chatbots", searchString);
    if (path === "websites")
      return client && performAlgoliaTextSearch(client, "websites", searchString);
    return undefined;
  }
});
