import {
  buildProperty
} from "@firecms/core";
import { v4 as uuidv4 } from "uuid";
import { Customization } from "./chatbots";
import HexColorTextField from "../../custom/HexColorTextField";

export const chatbotCustomizationProperty = buildProperty<Customization>({
  name: "Customization",
  validation: { required: false },
  expanded: true,
  previewProperties: ["company_logo"],
  dataType: "map",
  properties: {
    main_color: buildProperty({
      name: "Main Color",
      validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
      description: "Color in hexadecimal format, example: #2EC4B6",
      dataType: "string",
      defaultValue: "#2EC4B6",
      Field: HexColorTextField
    }),
    company_logo: buildProperty({
      name: "Company Logo",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4();
        }
      }
    }),
    chat_logo: buildProperty({
      name: "Chat Logo",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4();
        }
      }
    }),
    chat_logo_size: buildProperty({
      name: "Chat Logo Size",
      description: "Size of the chat logo in percentage to the total bubble",
      validation: { required: false, min: 54, max: 100 },
      dataType: "number",
      defaultValue: 55
    }),
    chat_logo_overtext: buildProperty({
      name: "Chat Logo Over Text",
      validation: { required: false },
      description: "Text to be displayed in the chat logo box, on top of the CTA (if style is applied)",
      dataType: "string"
    }),
    chat_logo_text: buildProperty({
      name: "Chat Logo Text",
      validation: { required: false },
      description: "Text to be displayed in the chat logo bubble. By default, CHAT NOW",
      defaultValue: "CHAT NOW",
      dataType: "string"
    }),
    popup_style: buildProperty({
      name: "Pop Up Style",
      description: "Style of the chat popup in the desktop and tablet version",
      validation: { required: false },
      defaultValue: "bubble",
      dataType: "string",
      enumValues: {
        bubble: "Bubble",
        sidepanel: "Side Panel"
      }
    }),
    button_style: buildProperty({
      name: "Button Style",
      description: "Style of the chat button in the desktop and tablet version",
      validation: { required: false },
      defaultValue: "button",
      dataType: "string",
      enumValues: {
        button: "Button",
        box: "Box"
      }
    }),
    notification_bubble_color: buildProperty({
      name: "Bubble notification Color",
      longName: "Notification Bubble Color",
      validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
      description: "Color used for the notification bubble. Must be hexadecimal, example: #FF6B6B",
      defaultValue: "#FF6B6B",
      dataType: "string",
      Field: HexColorTextField
    }),
    chat_autostart: buildProperty({
      name: "Desktop Chat Autostart",
      description: "If enabled, the chat will start automatically when the user enters the page",
      validation: { required: false },
      defaultValue: true,
      dataType: "boolean"
    }),
    chat_autostart_time: buildProperty({
      name: "Desktop Chat Autostart Time",
      description: "Time in miliseconds before the chat starts automatically",
      validation: { required: false },
      dataType: "number",
      defaultValue: 5000
    }),
    mobile_chat_autostart: buildProperty({
      name: "Mobile Chat Autostart",
      description: "If enabled, the chat will start automatically when the user enters the page",
      validation: { required: false },
      defaultValue: true,
      dataType: "boolean"
    }),
    mobile_chat_autostart_time: buildProperty({
      name: "Mobile Chat Autostart Time",
      description: "Time in miliseconds before the chat starts automatically",
      validation: { required: false },
      dataType: "number",
      defaultValue: 5000
    }),
    position: buildProperty({
      name: "Position",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        right: "Right",
        left: "Left"
      },
      defaultValue: "right"
    }),
    lateral_distance: buildProperty({
      name: "Lateral Distance",
      validation: { required: false },
      dataType: "number",
      defaultValue: 20
    }),
    bottom_distance: buildProperty({
      name: "Bottom Distance",
      validation: { required: false },
      dataType: "number",
      defaultValue: 20
    }),
    custom_font_name: buildProperty({
      name: "Custom Font Name",
      validation: { required: false },
      dataType: "string"
    }),
    custom_regular_font_woff: buildProperty({
      name: "Custom Regular Font WOFF",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff";
        }
      }
    }),
    custom_regular_font_woff2: buildProperty({
      name: "Custom Regular Font WOFF2",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff2"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff2";
        }
      }
    }),
    custom_bold_font_woff: buildProperty({
      name: "Custom Bold Font WOFF",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff";
        }
      }
    }),
    custom_bold_font_woff2: buildProperty({
      name: "Custom Bold Font WOFF2",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff2"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff2";
        }
      }
    })
  }
});

export const chatbotCustomizationWithNoDefaultsProperty = buildProperty<Customization>({
  name: "Customization",
  validation: { required: false },
  expanded: true,
  previewProperties: ["company_logo"],
  dataType: "map",
  properties: {
    main_color: buildProperty({
      name: "Main Color",
      validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
      description: "Color in hexadecimal format, example: #2EC4B6",
      dataType: "string",
      Field: HexColorTextField
    }),
    company_logo: buildProperty({
      name: "Company Logo",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4();
        }
      }
    }),
    chat_logo: buildProperty({
      name: "Chat Logo",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "images",
        acceptedFiles: ["image/*"],
        maxSize: 1024 * 1024,
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4();
        }
      }
    }),
    chat_logo_size: buildProperty({
      name: "Chat Logo Size",
      description: "Size of the chat logo in percentage to the total bubble",
      validation: { required: false, min: 54, max: 100 },
      dataType: "number"
    }),
    chat_logo_overtext: buildProperty({
      name: "Chat Logo Over Text",
      validation: { required: false },
      description: "Text to be displayed in the chat logo box, on top of the CTA (if style is applied)",
      dataType: "string"
    }),
    chat_logo_text: buildProperty({
      name: "Chat Logo Text",
      validation: { required: false },
      description: "Text to be displayed in the chat logo bubble",
      dataType: "string"
    }),
    popup_style: buildProperty({
      name: "Pop Up Style",
      description: "Style of the chat popup in the desktop and tablet version",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        bubble: "Bubble",
        sidepanel: "Side Panel"
      }
    }),
    button_style: buildProperty({
      name: "Button Style",
      description: "Style of the chat button in the desktop and tablet version",
      validation: { required: false },
      defaultValue: "button",
      dataType: "string",
      enumValues: {
        button: "Button",
        box: "Box"
      }
    }),
    notification_bubble_color: buildProperty({
      name: "Bubble notification Color",
      longName: "Notification Bubble Color",
      validation: { required: false, matches: "^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$" },
      description: "Color used for the notification bubble. Must be hexadecimal, example: #FF6B6B",
      dataType: "string",
      Field: HexColorTextField
    }),
    chat_autostart: buildProperty({
      name: "Desktop Chat Autostart",
      description: "If enabled, the chat will start automatically when the user enters the page",
      validation: { required: false },
      defaultValue: null,
      dataType: "boolean"
    }),
    chat_autostart_time: buildProperty({
      name: "Desktop Chat Autostart Time",
      description: "Time in miliseconds before the chat starts automatically",
      validation: { required: false },
      dataType: "number"
    }),
    mobile_chat_autostart: buildProperty({
      name: "Mobile Chat Autostart",
      description: "If enabled, the chat will start automatically when the user enters the page",
      validation: { required: false },
      defaultValue: null,
      dataType: "boolean"
    }),
    mobile_chat_autostart_time: buildProperty({
      name: "Mobile Chat Autostart Time",
      description: "Time in miliseconds before the chat starts automatically",
      validation: { required: false },
      dataType: "number"
    }),
    position: buildProperty({
      name: "Position",
      validation: { required: false },
      dataType: "string",
      enumValues: {
        right: "Right",
        left: "Left"
      }
    }),
    lateral_distance: buildProperty({
      name: "Lateral Distance",
      validation: { required: false },
      dataType: "number"
    }),
    bottom_distance: buildProperty({
      name: "Bottom Distance",
      validation: { required: false },
      dataType: "number"
    }),
    custom_font_name: buildProperty({
      name: "Custom Font Name",
      validation: { required: false },
      dataType: "string"
    }),
    custom_regular_font_woff: buildProperty({
      name: "Custom Regular Font WOFF",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff";
        }
      }
    }),
    custom_regular_font_woff2: buildProperty({
      name: "Custom Regular Font WOFF2",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff2"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff2";
        }
      }
    }),
    custom_bold_font_woff: buildProperty({
      name: "Custom Bold Font WOFF",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff";
        }
      }
    }),
    custom_bold_font_woff2: buildProperty({
      name: "Custom Bold Font WOFF2",
      validation: { required: false },
      dataType: "string",
      storage: {
        storagePath: "customfonts",
        acceptedFiles: [".woff2"],
        metadata: {
          cacheControl: "max-age=1000000"
        },
        fileName: (context) => {
          return uuidv4() + ".woff2";
        }
      }
    })
  }
});
