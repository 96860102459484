import {
  AdditionalFieldDelegate,
  buildCollection,
  buildEntityCallbacks,
  DataSource,
  EntityOnSaveProps,
  EntityValues,
  User
} from "@firecms/core";
import { v4 as uuidv4 } from "uuid";

import { knowledgeCollection } from "../knowledge";
import { chatbotsChangesCollection } from "./chatbots_changelog";
import { conversationsCollection } from "../conversations";
import { DocumentReference } from "firebase/firestore";
import { ChatbotWidget, chatbotWidgets } from "../../chatbot_widget";
import { triggerCollections } from "../triggers";
import { eventsCollection } from "../events";
import { chatbotPreview } from "../../views/chatbotView";
import { getDefaultSampleTriggers } from "../../default_triggers";
import config from "../../config";
import { chatbotCollectionProperties } from "./chatbots_properties";
import { chatbotPromptView } from "../../views/chatbot_prompt_view";
import { chatbotCustomizationView } from "../../views/chatbot_customization_view";
import { MemberRole } from "../team";

export type Assistant = {
  name: string;
  avatar: string;
}

export type CustomProperty = {
  name: string;
  type: "boolean"|"string"|"number"|"date";
  description: string;
}

export type Customization = {
  popup_style: "bubble" | "sidepanel";
  button_style: "button" | "box";
  main_color: string;
  company_logo: string;
  chat_logo: string;
  chat_logo_size: number;
  chat_logo_text: string;
  chat_logo_overtext?: string;
  notification_bubble_color: string;
  chat_autostart: boolean;
  chat_autostart_time: number;
  mobile_chat_autostart: boolean;
  mobile_chat_autostart_time: number
  position: string;
  lateral_distance: number;
  bottom_distance: number;
  custom_font_name: string;
  custom_regular_font_woff: string;
  custom_regular_font_woff2: string;
  custom_bold_font_woff: string;
  custom_bold_font_woff2: string;
}

export type Chatbot = {
  name: string;
  description: string;
  status: "live" | "disabled";
  prompt: string
  restrictions: string;
  assistant: Assistant;
  customization: Customization;
  initialMessage: string;
  initialQuestion: string;
  initialSuggestionChips: string[];
  conversationCompleted: string;
  tags: string[];
  createdAt: Date;
  updatedAt: Date;
  updatedBy: Partial<User>|null;
  customProperties: CustomProperty[];
  salesforceId: string;
  apiKey: string;
}

type ChatbotWithNoPrompt = Omit<Chatbot, "prompt" | "restrictions" | "customization" | "assistant">;

const chatbotCallbacks = buildEntityCallbacks<ChatbotWithNoPrompt>({
  onPreSave(entitySaveProps: EntityOnSaveProps<ChatbotWithNoPrompt>): Promise<Partial<EntityValues<ChatbotWithNoPrompt>>> | Partial<EntityValues<ChatbotWithNoPrompt>> {
    const user = entitySaveProps.context.authController.user;
    if (!user) throw new Error("User is not authenticated");
    if (entitySaveProps.status === "copy") {
      console.log(entitySaveProps.previousValues)
      // If the chatbot is a copy,
      delete entitySaveProps.values.apiKey;
      delete entitySaveProps.values.createdAt;
      delete entitySaveProps.values.updatedBy;
    }
    entitySaveProps.values.updatedBy = { uid: user.uid, displayName: user.displayName, email: user.email };
    if (!entitySaveProps.values.apiKey) entitySaveProps.values.apiKey = uuidv4();
    return entitySaveProps.values;
  },
  async onSaveSuccess(entitySaveProps: EntityOnSaveProps<ChatbotWithNoPrompt>): Promise<void> {
    if (entitySaveProps.status === "copy" || entitySaveProps.status === "new") {
      // If the chatbot is a copy, we need to create the default triggers
      const defaultTriggers = getDefaultSampleTriggers();
      let triggerToSaves = defaultTriggers.singleLocationTriggers;
      if (entitySaveProps.values.tags?.some(tag => tag.toLowerCase().includes("multi location"))) {
        triggerToSaves = defaultTriggers.multiLocationTriggers;
      }
      for (const triggerToSave of triggerToSaves) {
        await entitySaveProps.context.dataSource.saveEntity({
          path: `chatbots/${entitySaveProps.entityId}/triggers/`,
          values: { ...triggerToSave },
          status: "new"
        });
      }
    }
  }
});

export const landingPageUrlAdditionalField: AdditionalFieldDelegate<ChatbotWithNoPrompt> = {
  key: "landingPageUrl",
  name: "Landing Page URL",
  Builder: (props, context) => {
    return <a target="_blank" href={`${config.LANDING_PAGE_URL}?t=${props.entity.values.apiKey}`} rel="noreferrer">{`${config.LANDING_PAGE_URL}?t=${props.entity.values.apiKey}`}</a>;
  },
  dependencies: ["apiKey"]
};

export const chatbotsCollection = buildCollection<ChatbotWithNoPrompt>({
  name: "Chatbots",
  singularName: "Chatbot",
  textSearchEnabled: true,
  path: "chatbots",
  id: "chatbots",
  icon: "chat",
  exportable: false,
  entityViews: [
    chatbotPreview,
    chatbotPromptView,
    chatbotCustomizationView
  ],
  editable: true,
  hideIdFromForm: true,
  openEntityMode: "side_panel",
  hideIdFromCollection: true,
  subcollections: [conversationsCollection, knowledgeCollection, triggerCollections, eventsCollection, chatbotsChangesCollection],
  group: "Main",
  callbacks: chatbotCallbacks,
  additionalFields: [landingPageUrlAdditionalField],
  permissions: ({ authController, user }) => ({
    read: true,
    edit: [MemberRole.admin, MemberRole.editor].includes(authController.extra?.role),
    create: [MemberRole.admin, MemberRole.editor].includes(authController.extra?.role),
    delete: [MemberRole.admin].includes(authController.extra?.role)
  }),
  properties: chatbotCollectionProperties
});

export const getChatbot = async (dataSource: DataSource, chatbotRef?: DocumentReference): Promise<Chatbot|undefined> => {
  if (!chatbotRef) return;
  const chatbot = await dataSource.fetchEntity<Chatbot>({
    path: "chatbots",
    entityId: chatbotRef.id
  });
  return chatbot?.values;
}

export const getChatbotWidgetVersion = (chatbotEnvironment?: string): ChatbotWidget|undefined => {
  if (!chatbotEnvironment) return;
  return chatbotWidgets.find((widget) => widget.environment === chatbotEnvironment);
}
